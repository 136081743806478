<template>
  <div class="bodys">
    <!-- 导航栏 -->
    <div class="header">
      <div class="header-img">
        <img src="../../static/logo.jpg" alt="" class="logo" />
      </div>
      <div class="text">
        <div class="text-bu" @click="scrollToSection('home')"><el-link>首页</el-link></div>
        <div class="text-bu" @click="scrollToSection('about')"><el-link>关于我们</el-link></div>
        <div class="text-bu" @click="scrollToSection('scenes')"><el-link>应用场景</el-link></div>
        <div class="text-bu" @click="scrollToSection('business')"><el-link>四大业务</el-link></div>
        <div class="text-bu" @click="scrollToSection('contact')"><el-link>联系我们</el-link></div>
      </div>
    </div>
    <!-- 介绍图 -->
    <div class="container" id="home">
        <img src="../../static/首图.png" alt="图片" class="shou" />
        <div class="overlay">
          <b>工业化种植综合服务提供商</b> <br/>
          <div class="overlay-text">Controlled Environment Cultivation Integrated Service Provider</div>
        </div>
    </div>
    <!--关于我们-->
    <div>
        
    </div>
    <!-- 人物图 -->
    <div>

    </div>
    <!-- 应用场景 -->
    <div>

    </div>
    <!-- 四稳 -->
    <div>

    </div>
    <!-- 四大业务 -->
    <div>
    
    </div>
    <!-- 联系我们 -->
    <div>

    </div>
    <!-- 底栏 -->
    <div>

    </div>

  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},

  mounted() {},

  methods: {
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  },

};
</script>

<style scoped>

.header{
    width: 100%;
    height: 130px;
    background: #FFFFFF;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
}

.header-img {
  width: 50%;
}
.logo {
  max-width: 25%;
  padding-left: 45%;
}
.text {
  width: 45%;
  display: flex;
  align-items: center;
  font-size: 100px;
}
.text-bu {
  margin-left: 60px;
  width: 110px;
  font-size: 20px;
}

.header {
  display: flex;
  height: 120px;
  width: 100%;
  align-items: center;
}
.container{
    position: relative;
    width: 1920px;
    height: 800px;
}
.shou{
    width: 1920px;

}
.overlay {
  /* position: absolute; 将div设置为绝对定位 */
  top: 25%; /* 距离顶部的偏移量 */
  left: 35%; /* 距离左边的偏移量 */
  background-color: rgba(49, 49, 42, 0.849); /* 设置div的背景颜色和透明度 */
  color: white; /* 设置div的文本颜色 */
  padding:60px;
  z-index: 1; /* 设置堆叠顺序，值越大越靠前 */
  width: 25%;
  /* height: 90px; */
  font-size: 2vw;
}
.overlay-text{
    font-size: 0.08vw;
    color: rgb(150, 157, 172);
}
</style>
