import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElmentUi from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import lazy from './views/directives/lazy'


Vue.config.productionTip = false
Vue.use(ElmentUi)
Vue.directive('lazy',lazy)

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
