export default {
    // bind 钩子，在绑定元素时触发
    bind(el) {
      const imgSrc = el.src;
      el.src = '';
  
      // 观察者
      const observer = new IntersectionObserver(([{ isIntersecting }]) => {
        console.log('触发了');
        // 元素出现在可视区域，和离开可视区域被触发
        if (isIntersecting) {
          // 加载图片
          el.src = imgSrc;
          // 停止观察
          observer.unobserve(el);
        }
      });
  
      observer.observe(el);
    },
  
    // update 钩子，在元素更新时触发
    update(el) {
      // 在这里可以处理更新时的逻辑，如果有需要的话
    },
  };
  