<template>
  <div class="bodys" id="app">
    <!-- 导航栏 -->
    <div class="header">
      <div class="header-img">
        <img src="../../static/logo.jpg" alt="" class="logo" />
      </div>
      <div class="text">
        <div class="text-bu">
          <el-button type="text" @click="scrollToSection('home')"
            >首页</el-button
          >
        </div>
        <div class="text-bu">
          <el-button type="text" @click="scrollToSection('about')"
            >关于我们</el-button
          >
        </div>
        <div class="text-bu">
          <el-button type="text" @click="scrollToSection('scenes')"
            >应用场景</el-button
          >
        </div>
        <div class="text-bu">
          <el-button type="text" @click="scrollToSection('business')"
            >四大业务</el-button>
        </div>
        <div class="text-bu">
          <el-button type="text" @click="scrollToSection('cooperation')"
            >合作伙伴</el-button>
        </div>
        <div class="text-bu">
          <el-button
            type="text"
            class="text-button"
            @click="scrollToSection('contact')"
            >联系我们</el-button>
        </div>
      </div>
    </div>

    <!-- 内容 -->
    <div>
      <!-- 首页 -->
      <div class="container" id="home">
        <img src="../../static/106.png" alt="图片" class="shou" />
        <div class="overlay">
          <b>工业化种植综合服务提供商</b> <br/>
          <div class="overlay-text">
            Controlled Environment Cultivation Integrated Service Provider
          </div>
        </div>
      </div>
 
      <!-- 关于我们 -->
      <div class="About_Us" id="about">
        <div class="About_Us-body">
          <div class="About_Us-a" >
            <span style="font-size: 25px"
              ><b><big>关于我们</big></b></span
            >
            <div style="font-size: 10px; color: rgb(177, 177, 177)">
              ABOUT US
            </div>
          </div>
          <div class="About_Us-ui">
            <p>
                武汉植物方舟智能科技有限公司位于武汉光谷，专注物联网在现代农业领域的探索与应用，致力于为种植企业及科研团队提供更全面、更专业的工业化种植整体解决方案——
            <br />
            从植物照明控制、传感器、控制器等基础产品设备，到智能环控系统、智能灌溉系统、中央控制系统等智慧农业物联网平台，再到植物生长模型、种植技术、种植方案等业务支持，持续响应工业化种植应用过程中的各类需求，为种植企业及科研团队助力。
         
            </p>
          </div>
          <div class="About_Us-img-1">
            <div class="About-sz">
              <div class="About-shu">82%</div>
              <div class="About-zi">技术团队占比</div>
            </div>
            <div class="About-zho"></div>
            <div class="About-sz-2">
              <div class="About-shu">100+</div>
              <div class="About-zi">知识产权</div>
            </div>
            <div class="About-zho"></div>
            <div class="About-sz">
              <div class="About-shu">1000㎡</div>
              <div class="About-zi">实验室面积</div>
            </div>
          </div>
        </div>

        <div class="body-he">
          <div class="body-img">
            <img
              src="../../static/2.jpg"
              alt="关于我们仰视图"
              width="391px"
              height="540px"
            />
          </div>
          <div class="boy-image-bule"></div>
        </div>
      </div>

      <div class="About-image-ren">
        <img src="../../static/3.jpg" alt="人物图" class="img-ren" />
        <div class="About-image-ren-ui">
          <div class="About-image-ren-ui-1">
            <b>工业化种植——更高阶的智能设施农业</b>
          </div>
          <br />
          <div class="About-image-ren-ui-2">
          <p >
            “工业化种植”
            是指综合运用物联网、传感设备结合表型分析生长监测等技术手段的一种全面标准化、机械化、自动化、密集型的变革性农业生产技术，为作物提供“安全、有效、可控、恒定”的种植环境，实现“稳产、稳质、稳量、稳收”的种植优势。区别于常见的植物工厂，工业化种植更强调了核心技术“植物生长模型”的研发与应用，属于更高阶的智能设施农业。

          </p>  
        </div>
          <br />
          _____________________
        </div>
      </div>

      <!-- 应用场景 -->
      <div class="appli" id="scenes">
        <div class="text-c">
          <span style="font-size: 25px"
            ><b><big>应用场景</big></b></span
          >
          <div style="font-size: 10px; color: rgb(177, 177, 177)">
            Application scenario
          </div>
        </div>

        <div class="image-list">
          <div class="image-dan">
            <img src="../../static/222.png" alt="人工光植物工厂" />
            <div class="image-dan-text">人工光植物工厂</div>
          </div>
          <div class="image-dan">
            <img src="../../static/12.png" alt="玻璃温室" />
            <div class="image-dan-text">玻璃温室</div>
          </div>
          <div class="image-dan">
            <img src="../../static/13.png" alt="垂直农场" />
            <div class="image-dan-text">垂直农场</div>
          </div>
        </div>

        <div class="image-list">
          <div class="image-dan">
            <img src="../../static/333.png" alt="育种加速器" />
            <div class="image-dan-text">育种加速器</div>
          </div>
          <div class="image-dan">
            <img src="../../static/111.png" alt="集装箱人工气候室" />
            <div class="image-dan-text">集装箱人工气候室</div>
          </div>
          <div class="image-dan">
            <img src="../../static/16.png" alt="农业自动化系统集成 " />
            <div class="image-dan-text">农业自动化系统集成</div>
          </div>
        </div>
      </div>

      <div>
        <div class="scenes-qwe">
          <img src="../../static/10.jpg" alt="背景图" class="scenes-image" />
          <div class="scenes-list">
            <div class="bbb">
              <div class="scenes-div">稳产</div>
              
            </div>
            <div class="bbb">
              <div class="scenes-div">稳质</div>
              
            </div>
            <div class="bbb">
              <div class="scenes-div">稳量</div>
              
            </div>
            <div class="scenes-div">稳收</div>
          </div>
        </div>
      </div>

      <!-- 业务体系 -->
      <div class="business-wqe">
        <div class="business_system" id="business">
          <div style="font-size: 25px">
            <big><b>四大业务</b></big>
          </div>
          <div style="font-size: 10px; color: rgb(177, 177, 177)">
            BUSINESS SYSTEM
          </div>
        </div>
        <!-- 标题 -->
        <div class="business-text">围绕工业化种植,植物方舟支持四大业务</div>

        <div class="business-list">
          <div class="business-border">
            <img
              src="../../static/21.png"
              alt="产品销售"
              class="business-image"
            />
            <div class="buiness-text">产品销售</div>
            <div class="buiness-wen">
              全球质量保障 <br />
              规模成本更低 <br />
              售后技术支持
            </div>
          </div>
          <div class="business-border">
            <img
              src="../../static/22.png"
              alt="项目集成"
              class="business-image"
            />
            <div class="buiness-text">项目集成</div>
            <div class="buiness-wen">
              80%全栈自研 <br />
              统一系统架构 <br />
              打通互联鸿沟
            </div>
          </div>
          <div class="business-border">
            <img
              src="../../static/23.png"
              alt="项目运维"
              class="business-image"
            />
            <div class="buiness-text">项目运维</div>
            <div class="buiness-wen">
              专业种植团队 <br />
              种植技术服务 <br />
              耗材标准服务
            </div>
          </div>

          <div class="business-border">
            <img
              src="../../static/24.png"
              alt="产业合作"
              class="business-image"
            />

            <div class="buiness-text">产业合作</div>
            <div class="buiness-wen">
              产品品牌授权 <br />
              种植技术标准 <br />
              产品检测标准
            </div>
          </div>
        </div>
      </div>

      <!-- 合作伙伴 -->
      <div id="cooperation">
        <div class="business_system" id="business">
          <div style="font-size: 25px">
            <big><b>合作伙伴</b></big>
          </div>
          <div style="font-size: 10px; color: rgb(177, 177, 177)">
            BUSINESS PARTBER
          </div>
        </div>
        <div class="partber-image-all">
          <div class="partber-image-1">
            <div>
              <img src="../../static/组 30.png" alt="11" class="par-image" />
            </div>
            <div>
              <img src="../../static/组 31.png" alt="12" class="par-image" />
            </div>
            <div>
              <img src="../../static/组 32.png" alt="13" class="par-image" />
            </div>
            <div>
              <img src="../../static/组 33.png" alt="14" class="par-image" />
            </div>
            <div>
              <img src="../../static/组 34.png" alt="15" class="par-image" />
            </div>
          </div>
          <div class="partber-image-2">
            <div>
              <img src="../../static/组 43.png" alt="21" class="par-image" />
            </div>
            <div>
              <img src="../../static/组 42.png" alt="22" class="par-image" />
            </div>
            <div>
              <img src="../../static/组 41.png" alt="23" class="par-image" />
            </div>
            <div>
              <img src="../../static/组 40.png" alt="24" class="par-image" />
            </div>
            <div>
              <img src="../../static/组 39.png" alt="25" class="par-image" />
            </div>
          </div>
          <div class="partber-image-3">
            <div>
              <img src="../../static/组 35.png" alt="31" class="par-image" />
            </div>

            <div>
              <img src="../../static/组 36.png" alt="32" class="par-image" />
            </div>
            <div>
              <img src="../../static/组 37.png" alt="33" class="par-image" />
            </div>
            <div>
              <img src="../../static/组 38.png" alt="34" class="par-image" />
            </div>
          </div>
        </div>
      </div>

      <!-- 联系我们-->
      <div>
        <div class="contact_us" id="contact">
          <span style="font-size: 35px"><b>联系我们</b></span>
        </div>
        <div class="contact_all">
          <div class="contact-list">
            <div class="contact-icon">
              <i class="el-icon-phone-outline" style="font-size: 40px"></i>
            </div>
            <div>
              <div>电话：</div>
              <div>400-138-8980</div>
            </div>
          </div>
          <div class="contact-list">
            <div class="contact-icon">
              <i class="el-icon-location-outline" style="font-size: 40px"></i>
            </div>
            <div>
              <div>地址:</div>
              <div>湖北武汉软件新城花城汇2号楼14楼</div>
            </div>
          </div>
          <div class="contact-list">
            <div class="contact-icon">
              <i class="el-icon-message" style="font-size: 40px"></i>
            </div>
            <div>
              <div>邮箱:</div>
              <div>contact@plantark.cn</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部 -->

    <div class="footer">
      <div class="footer-div">
        <div class="footer-text">
          <div>
            <div style="color: #fff; margin-bottom: 10px">友情链接:</div>
            <div style="color: #fff">
              <a href="http://www.hbaas.com/" style="color: #fff"
                >湖北省农业科学院</a
              >
              &ensp;·&ensp;
              <a href="http://www.hzau.edu.cn/" style="color: #fff"
                >华中农业大学</a
              >
            </div>
          </div>
          <div>
            <div style="margin-bottom: 5px">
              <img src="../../static/组 16.png" alt="" />
            </div>
            <div style="color: #fff">工业化种植综合服务提供商</div>
          </div>
        </div>
        <div style="text-align: center; margin-bottom: 10px">
          武汉植物方舟智能科技有限公司
        </div>
        <div style="text-align: center">
          鄂ICP备2023015149号 Copyright © 植物方舟 版权所有
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  mounted() {
    // 初始化页面缩放
    this.bodyScale();

    // 监听窗口大小改变事件，实时更新缩放比例
    // window.addEventListener("resize", this.bodyScale);
  },

  methods: {
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    // 等比例缩放的方法，可以放到VUE的method里
    bodyScale() {
      var deviceWidth = document.documentElement.clientWidth; // 获取当前分辨率下的可是区域宽度
      var scale = deviceWidth / 1920; // 分母——设计稿的尺寸
      console.log(deviceWidth, scale);
      document.documentElement.style.zoom = scale; // 放大缩小相应倍数
    },
  },
  beforeDestroy() {
    // 在组件销毁前移除事件监听器，以防止内存泄漏
    window.removeEventListener("resize", this.bodyScale);
  },
};
</script>

<style scoped>
*{
  -webkit-text-size-adjust: 30%;
}


.bodys {
  width: 100%;
  min-width: 1900px;
}

.header {
  width: 100%;
  height: 130px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}

.header-img {
  width: 50%;
}

.logo {
  max-width: 25%;
  /* padding-left: 45%; */
}

.text {
  width: 50%;
  display: flex;
  align-items: center;
  font-size: 100px;
}

.text-bu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 60px;
  width: 100px;
  font-size: 16px;
  color: black;
}

.text-bu:hover {
  color: green;
  /* 鼠标悬停时字体变绿 */
  border-bottom: 2px solid green;
}

/* 按钮字体为黑 */
.el-button {
  color: black;
  font-size: 16px;
}

.el-button:hover {
  color: green;
  /* 鼠标悬停时字体变绿 */
}

.header {
  display: flex;
  height: 120px;
  width: 100%;
  align-items: center;
}

.container {
  position: relative;
  width: 100%;
  height: 800px;
}

.shou {
  width: 100%;
  /* height: 800px; */
}

.overlay {
  position: absolute;
  /* 将div设置为绝对定位 */
  top: 356px;
  /* 距离顶部的偏移量 */
  left: 835px;
  /* 距离左边的偏移量 */
  background-color: rgba(49, 49, 42, 0.849);
  /* 设置div的背景颜色和透明度 */
  color: white;
  /* 设置div的文本颜色 */
  padding: 60px;
  z-index: 1;
  /* 设置堆叠顺序，值越大越靠前 */
  width: 500px;
  height: 90px;
  font-size: 40px;
  -webkit-text-size-adjust: none;
}

@media screen and (max-device-width: 520px) {
  .body-img {
    /* border: 1px solid red; */
    transform: scale(1.2);
  }
  .About_Us-ui {
    font-size: 22px !important;
  }
  .About-image-ren-ui-2 {
    font-size: 22px !important;
  }
  .About-image-ren-ui-1 {
    font-size: 22px !important;
  }
  .body-img img {
    position: relative;
    right: 10%;
    top: 60px;
    /* border: 1px solid red; */
  }
  .header {
    display: flex;
    justify-content: space-between;
    font-size: 36px !important;
  }
  .header .text-bu button {
    font-size: 26px !important;
  }

  .header .logo {
    padding-left: 0 !important;
  }
  .container img {
    height: 100%;
  }
  .container .overlay {
    width: 706px;
    height: 264px;
    right: 10px !important;
    left: auto;
  }
  .container .overlay b {
    width: 600px !important;
    display: inline-block;
    font-size: 50px !important;
  }
  .container .overlay .overlay-text {
    font-size: 25px !important;
  }
}

.overlay-text {
  font-size: 16px;
  color: rgb(150, 157, 172);
}

img {
  max-width: 100%;
  height: auto;
}

.About_Us {
  display: flex;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 3%;
  margin-bottom: 3%;
}

@media screen and (max-device-width: 520px) {
  .About_Us {
    display: flex;
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    margin-top: 3%;
    margin-bottom: 3%;
    justify-content: space-between;
  }
  .image-dan-text {
    right: 0;
    width: 100% !important;
  }
}
/* 设备的宽度在520像素到1080像素之间 */
@media screen and (min-device-width: 520px) and (max-device-width: 1080px) {
  .contact_all {
    width: 90% !important;
    justify-content: space-between !important;
    display: flex;
    margin-left: 5% !important;
    margin-right: 5% !important;
    /* border: 1px solid red; */
  }
  .business-list {
    width: 90% !important;
    margin-left: 5% !important;
    margin-right: 5% !important;

  }
  .business_system {
    margin-left: 5% !important;
  }
  .business-border {
    width: 25% !important;
    margin-left: 0px !important;
    margin-right: 25px;
  }
  .About_Us {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .appli {
    width: 90% !important;
    margin-left: 5% !important;
    margin-right: 5% !important;
  }
  .appli img {
    width: 100%;
  }
  .appli .image-dan {
    margin: 20px;
  }
  .partber-image-all {
    width: 90% !important;
    margin-left: 5% !important;
    margin-right: 5% !important;
    /* border: 1px solid red; */
  }
  .partber-image-all > div {
    width: 100%;
  }
  .partber-image-all > div > div {
    margin: 10px;
    width: 20%;
  }
  .partber-image-all > div > div img {
    width: 100%;
  }
  .About-image-ren-ui {
    left: 5% !important;
  }
  .partber-image-3 > div {
    width: 25% !important;
  }
}

.image-dan-text {
  width: 100% !important;
}
@media screen and (min-width: 1080px) and (max-width: 1500px) {
  .About-image-ren-ui {
    left: 15% !important;
  }
  .contact_all {
    width: 80% !important;
    justify-content: space-between !important;
    display: flex;
    margin-left: 10% !important;
    margin-right: 10% !important;
  }
  .business_system {
    margin-left: 10% !important;
  }
  .partber-image-all {
    width: 80% !important;
    margin-left: 10% !important;
    margin-right: 10% !important;
  }
  .partber-image-all > div {
    width: 100%;
  }
  .partber-image-all > div > div {
    margin: 10px;
    width: 20%;
  }
  .partber-image-all > div > div img {
    width: 100%;
  }
  .business-list {
    width: 80% !important;
    margin-left: 10% !important;
    margin-right: 10% !important;
  }
  .business-list > div {
    width: 25%;
  }
  .About_Us {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  
  }
  .appli {
    width: 80% !important;
    margin-left: 10% !important;
    margin-right: 10% !important;
  }
  .appli img {
    width: 100%;
  }
  .appli .image-dan {
    margin: 20px;
  }
}

.About_Us-b {
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 3%;
  margin-bottom: 3%;
}

.About_Us-body {
  width: 60%;
}

.About_Us-a {

  margin: 40px;
  margin-top: 80px;
  padding-left: 15px;
  border-left: 7px solid rgb(140, 184, 8);
}

.About_Us-ui {

  font-size: 16px; 
  font-weight: 400;
  color: #666666;
  width: 600px;
  height: 255px;
  margin-left: 40px;  
  line-height: 35px;
  
}

.About_Us-img-1 {

  display: flex;
  /* margin: 40px; */
  margin-top: 20px;
  margin-left: 5px;
  /* margin-left: 15px; */
}

.About_Us-img-small {
  display: flex;
  justify-content: space-around;
}

.About-image-ren {
  position: relative;
  /* 设置容器为相对定位 */
}

.About-image-ren-ui {
  position: absolute;
  /* 将div设置为绝对定位 */
  width: 400px;
  top: 25%;
  /* 距离顶部的偏移量 */
  left: 300px;
  /* 距离左边的偏移量 */
  color: white;
  /* 设置div的文本颜色 */
  padding: 55px;
  /* 设置div的内边距 */
  line-height: 2;
  z-index: 1;
  /* 设置堆叠顺序，值越大越靠前 */
}

.About-image-ren-ui-1 {
  font-size: 20px;
}

.About-image-ren-ui-2 {
  font-size: 16px;

}

.About-sz {

  width: 200px;
  padding: 16px;
}

.About-sz-2 {

  width: 200px;
  padding: 16px;
}

.About-shu {

  display: flex;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  width: 180px;
  height: 67px;
  font-size: 40px;
  font-weight: bold;
  color: #333333;
  line-height: 20px;
}

.About-zi {

  display: flex;
  width: 180px;
  height: 21px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 20px;
  justify-content: center;

}

.About-zho {
  width: 0px;
  height: 70px;
  border: 1px solid #d3d3d3;
  margin: 10px;
}

.big-image {
  position: absolute;
  z-index: -1;
  height: 160px;
}

.small-image {
  position: absolute;
  z-index: 1; 
  margin: 10px;
  widows: 30%;
  max-width: 100%;
  height: auto;
  transform: scale(0.8);
}

.small-image-1 {
  margin: 10px;
  widows: 30%;
  max-width: 100%;
  height: auto;
  transform: scale(0.8);
}

.small-image-2 {
  margin: 10px;
  widows: 30%;
  max-width: 100%;
  height: auto;
  transform: scale(0.8);
}

.small-image-3 {
  margin: 10px;
  widows: 30%;
  max-width: 100%;
  height: auto;
  transform: scale(0.8);
}

.body-img {
  position: absolute;
  width: 391px;
  height: 540px;
  margin-top: 50px;
  z-index: 1;
}

.body-he {
  width: 500px;
  height: 500px;
}

.boy-image-bule {
  position: relative;
  width: 230px;
  height: 270px;
  margin-left: 250px;
  background-color: rgb(140, 184, 8);
}

.appli {
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 3%;
  margin-bottom: 3%;
}
/* 设备的最大宽度必须小于或等于520像素 */
@media screen and (max-device-width: 520px) {
  .appli {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    margin-top: 3%;
    margin-bottom: 3%;
  }
  .appli img {
    width: 100%;
  }
  .appli .image-dan {
    margin: 20px;
  }
  
}
.business-list {
  display: flex;
  justify-content: center;
  margin: 50px;
}

.business-image {
  width: 130px;
  height: 110px;
  padding: 48.5px 65.49px 0px 65.49px;
  /* border: 1px solid blue; */
}

.business-text {
  width: 540px;
  height: 40px;
  font-size: 30px;
  font-weight: bold;
  color: #333333;
  line-height: 32px;
  margin-left: 690px;
  margin-right: 690px;
}

.buiness-wen {
  display: flex;
  width: 250px;
  height: 50px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  /* line-height: 30px; */
  margin-left: 10px;
  justify-content: center;
}

.business-border {
  width: 261px;
  height: 360px;
  border-radius: 0px 30px 0px 0px;
  opacity: 1;
  border: 2px solid #8cb808;
  margin-left: 25px;
}

.partber-image-all {
  width: 60%;
  margin-left: 21%;
}

.partber-image-1 {
  display: flex;
}

.partber-image-2 {
  display: flex;
}

.partber-image-3 {
  display: flex;
}

.scenes-qwe {
  position: relative;
  /* 设置容器为相对定位 */
  width: 100%;
  height: 353px;
}

.scenes-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  /* 将div设置为绝对定位 */
  top: -30px;
  /* 距离顶部的偏移量 */
  left: 15%;
  /* 距离左边的偏移量 */
  color: white;
  /* 设置div的文本颜色 */
  padding: 60px;
  z-index: 1;
  /* 设置堆叠顺序，值越大越靠前 */
  font-size: 40px;
}

.scenes-div {
  display: flex;
  margin: 50px;
  width: 185px;
  height: 184px;
  opacity: 1;
  border: 2px solid #999999;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  /* 将边框变成圆形 */
  font-size: 33px;
}

.business_system {
  margin: 20px;
  margin-top: 100px;
  margin-left: 400px;
  padding-left: 15px;
  border-left: 7px solid rgb(140, 184, 8);
}

.partber-image-3 > div {
  width: 25% !important;
}

@media screen and (max-device-width: 520px) {
  .contact-list {
    height: 116px !important;
    width: 120px; 
  }
  .business_system {
    margin-left: 0px;
  }
  .business-border {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
  }
  .business-border > img {
    width: 50%;
  }
  .partber-image-all {
    width: 100%;
    margin-left: 0;
  }
  .partber-image-all > div {
    box-sizing: border-box;
    width: 100%;
  }
  .partber-image-all > div > div {
    margin: 10px;
    width: 20%;
  }
  .partber-image-all > div > div img {
    height: 166px;
    width: 100%;
  }
  .aaa {
    border: 1px solid salmon;
    height: 100px;
  }
  .About_Us-ui {
    width: 88% !important;
  }
  .About-image-ren-ui {
    left: 0 !important;
    width: 46%;
  }
  .scenes-list {
    width: 100%;
    left: 0;
  }
  .business-list {
    margin-left: 0;
    margin-right: 0;
  }
  .contact_all {
    margin-left: 10% !important;
    margin-right: 10% !important;
  }
}

.bbb {
  display: flex;
  align-items: center;
}

.buiness-text {
  width: 120px;
  height: 32px;
  font-size: 24px;
  font-weight: bold;
  color: #8cb808;
  margin: 32px 82px 25px 83px;
}

.business-img {
  margin-left: 190px;
}

.par-image {
  margin: 4px;
}

.contact_us {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  margin-top: 150px;
}

.contact_all {
  display: flex;
  justify-content: space-around;
  margin: 50px;
  margin-top: 50px;
}

@media screen and (max-device-width: 520px) {
  * {
    box-sizing: border-box;
  }
  .contact_all {
    display: flex;
    justify-content: space-between;
  }
  .contact_all > div {
    width: 20%;
  }
}

.contact-list {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgb(168, 168, 168);
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  width: 320px;
  height: 50px;
  font-size: 16px;
  color: rgb(83, 83, 83);
}

.contact-icon {
  margin-right: 20px;
}

.text-c {
  margin: 20px;
  padding-left: 15px;
  border-left: 7px solid rgb(140, 184, 8);
}

.image-list {
  /* border: 1px solid black; */
  display: flex;
  justify-content: space-between;
}

.image-dan {
  position: relative;
  flex-basis: calc(33.33% - 10px);
  /* 一行放三个图片，每个图片占据33.33%的宽度，并减去间距 */
  margin: 5px;
  /* 图片之间的间距 */
}

.image-dan img {
  width: 100%;
  /* border: 1px solid red; */
}

.image-dan-text {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  /* 将div设置为绝对定位 */
  z-index: 1;
  /* 设置堆叠顺序，值越大越靠前 */
  bottom: 0%;
  /* 距离顶部的偏移量 */
  left: 0%;
  /* 距离左边的偏移量 */
  color: white;
  background-color: rgba(51, 51, 51, 0.849);
  /* 设置div的背景颜色和透明度 */
  width: 357px;
  height: 47px;
  margin-right: 20px;
  /* 控制文字之间的间距 */
  font-weight: bold;
  /* 文字加粗 */
}

.footer-top {
  display: flex;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  width: 100%;
  height: 30px;
  background: rgba(37, 37, 37, 0.945);
  border-top: 5px solid rgb(140, 184, 8);
  justify-content: center;
  color: rgb(255, 255, 255);
}

.footer {
  width: 100%;
  background: #000;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #7b7b7b !important;
}

.footer-text {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px 0 15px;
  margin: 20px 0px 10px;
  color: rgb(136, 136, 136);
  border-bottom: 1px solid rgb(212, 212, 212);
}
.footer-div{
  width: 50%;
}
.footer-image {
  margin: 20px;
  display: flex;
  justify-content: center;
}



</style>