import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CheShiView from '../views/CheShiView.vue'

Vue.use(VueRouter)

const routes = [

  {
    path:"/",
    name:"home",
    component:HomeView,
  },
  {
    path:"/ce",
    name:"ce",
    component:CheShiView
  }

]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
